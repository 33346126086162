var render, staticRenderFns
import script from "./ProductTitle.vue?vue&type=script&lang=js&"
export * from "./ProductTitle.vue?vue&type=script&lang=js&"
import style0 from "./ProductTitle.vue?vue&type=style&index=0&id=581c4bfa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581c4bfa",
  null
  
)

export default component.exports