var render, staticRenderFns
import script from "./TagInput.vue?vue&type=script&lang=js&"
export * from "./TagInput.vue?vue&type=script&lang=js&"
import style0 from "./TagInput.vue?vue&type=style&index=0&id=6602e668&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6602e668",
  null
  
)

export default component.exports